import * as React from "react"
// import { graphql } from "gatsby"
// import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutDocsPublishedInTheFuture } from "../lib/helpers"

import Layout from "../components/layout"
// import { HeroPost } from "../components/hero-post"
// import { FeaturedPosts } from "../components/featured-posts"
import { Heading, Text } from "../ziibo"
import SEO from "../components/seo"

const AboutPage = () => {
	return (
		<Layout>
			<SEO title="About" />
			<main style={{ margin: "24px auto", width: "80%" }}>
				<Heading>About</Heading>
				<Text>Along the Seams is a baseball blog and podcast.</Text>
			</main>
		</Layout>
	)
}

export default AboutPage
